import React from "react";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";

const Terms = (props) => (
    <Row>
        <Col xs={12} md={6} mdOffset={3}>
            <h2>Terms and Conditions</h2>
                <p>These terms and conditions outline the rules and regulations for the use of Eternal Warfare's Website.</p>
                <p>By accessing this website we assume you accept these terms and conditions in full. Do not continue to use Eternal Warfare's website 
                if you do not accept all of the terms and conditions stated on this page.</p>
                <p>The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice
                and any or all Agreements: “Client”, “You” and “Your” refers to you, the person accessing this website
                and accepting the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers
                to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves, or either the Client
                or ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake
                the process of our assistance to the Client in the most appropriate manner, whether by formal meetings
                of a fixed duration, or any other means, for the express purpose of meeting the Client’s needs in respect
                of provision of the Company’s stated services/products, in accordance with and subject to, prevailing law
                of Uniited States. Any use of the above terminology or other words in the singular, plural,
                capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.</p>
                <p><strong>Cookies</strong></p>
                <p>We employ the use of cookies. By using Eternal Warfare's website you consent to the use of cookies 
                in accordance with Eternal Warfare’s privacy policy.</p>
                <p>Most of the modern day interactive web sites
                use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site
                to enable the functionality of this area and ease of use for those people visiting. Some of our 
                affiliate / advertising partners may also use cookies.</p>

            
            <p><strong>Iframes</strong></p>
                <p>Without prior approval and express written permission, you may not create frames around our Web pages or
                use other techniques that alter in any way the visual presentation or appearance of our Web site.</p>
            <p><strong>Reservation of Rights</strong></p>
                <p>We reserve the right at any time and in its sole discretion to request that you remove all links or any particular
                link to our Web site. You agree to immediately remove all links to our Web site upon such request. We also
                reserve the right to amend these terms and conditions and its linking policy at any time. By continuing
                to link to our Web site, you agree to be bound to and abide by these linking terms and conditions.</p>
            <p><strong>Removal of links from our website</strong></p>
                <p>If you find any link on our Web site or any linked web site objectionable for any reason, you may contact
                us about this. We will consider requests to remove links but will have no obligation to do so or to respond
                directly to you.</p>
                <p>Whilst we endeavour to ensure that the information on this website is correct, we do not warrant its completeness
                or accuracy; nor do we commit to ensuring that the website remains available or that the material on the
                website is kept up to date.</p>
            <p><strong>Content Liability</strong></p>
                <p>We shall have no responsibility or liability for any content appearing on your Web site. You agree to indemnify
                and defend us against all claims arising out of or based upon your Website. No link(s) may appear on any
                page on your Web site or within any context containing content or materials that may be interpreted as
                libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or
                other violation of, any third party rights.</p>
            <p><strong>Disclaimer</strong></p>
                <p>To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to 
                our website and the use of this website (including, without limitation, any warranties implied by law in respect of 
                    satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:</p>
                <ol>
                <li>limit or exclude our or your liability for death or personal injury resulting from negligence;</li>
                <li>limit or exclude our or your liability for fraud or fraudulent misrepresentation;</li>
                <li>limit any of our or your liabilities in any way that is not permitted under applicable law; or</li>
                <li>exclude any of our or your liabilities that may not be excluded under applicable law.</li>
                </ol>
                <p>The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a)
                are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or
                in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort
                (including negligence) and for breach of statutory duty.</p>
                <p>To the extent that the website and the information and services on the website are provided free of charge,
                we will not be liable for any loss or damage of any nature.</p>

            <h2>Privacy Policy</h2> 
            <p>Eternal Warfare LLC has built eternalwarfare.org as a Commercial website designed to facilite sales of event tickets. This SERVICE is provided by
              Eternal Warfare LLC  and is intended for use as is.
            </p>
            <p>This page is used to inform visitors regarding our policies with the collection, use, and disclosure
              of Personal Information if anyone decided to use our Service.
            </p>
            <p>If you choose to use our Service, then you agree to the collection and use of information in
              relation to this policy. The Personal Information that we collect is used for providing and improving
              the Service. We will not use or share your information with anyone except as described
              in this Privacy Policy.
            </p>
            <p>The terms used in this Privacy Policy have the same meanings as in our Terms and Conditions, which is
              accessible at Eternal Warfare unless otherwise defined in this Privacy Policy.
            </p>
            <p><strong>Information Collection and Use</strong></p>
            <p>For a better experience, while using our Service, we may require you to provide us with certain
              personally identifiable information, including but not limited to Email, and First and Last Name. The information that we request will be retained by us and used as described in this privacy policy.
            </p>
            <p>The app does use third party services that may collect information used to identify you.</p>
            <div>
              <p>Link to privacy policy of third party service providers used by the app</p>
              <ul></ul>
            </div> 
            <p><strong>Log Data</strong></p>
            <p> We want to inform you that whenever you use our Service, in a case of
              an error in the app we collect data and information (through third party products) on our server(s)
              called Log Data. This Log Data may include information such as your device Internet Protocol (“IP”) address,
              device name, operating system version, the configuration of the app when utilizing our Service,
              the time and date of your use of the Service, and other statistics.
            </p>
            <p><strong>Data Retention</strong></p>
            <p> We will do our absolute best to clean up data that is no longer in use. After an account has been inactive for 2 years,
              we will delete all records from our databases that reference the user's purchases. After 5 years of inactivity, the account
              and all associated data will be removed entirely.</p>
            <p><strong>Data Retrieval</strong></p>
            <p> If you wish to retrieve the information we have about you, please send an email to  
              <a href="mailto:data@eternalwarfare.org"> data@eternalwarfare.org </a></p>
            <p><strong>Cookies</strong></p>
            <p>Cookies are files with a small amount of data that are commonly used as anonymous unique identifiers.
              These are sent to your browser from the websites that you visit and are stored on your device's internal
              memory.
            </p>
            <p>You have the option to
              either accept or refuse these cookies and know when a cookie is being sent to your device. If you choose
              to refuse our cookies, you may not be able to use some portions of this Service.
            </p>
            <p><strong>Email</strong></p>
            <p> We will occasionally send you emails related to other events you may be interested in based on your past purchases. 
              If you wish to unsubscribe, you may do so at any point by emailing 
              <a href="mailto:unsubscribe@eternalwarfare.org"> unsubscribe@eternalwarfare.org</a>.</p>
            <p><strong>Service Providers</strong></p>
            <p> We may employ third-party companies and individuals due to the following reasons:</p> 
            <ul>
              <li>To facilitate our Service;</li> 
              <li>To provide the Service on our behalf;</li> 
              <li>To perform Service-related services; or</li> 
              <li>To assist us in analyzing how our Service is used.</li>
            </ul> 
            <p> We want to inform users of this Service that these third parties have access to
              your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However,
              they are obligated not to disclose or use the information for any other purpose.
            </p>
            <p><strong>Security</strong></p>
            <p> We value your trust in providing us your Personal Information, thus we are striving
              to use commercially acceptable means of protecting it. But remember that no method of transmission over
              the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee
              its absolute security.
            </p>
            <p><strong>Links to Other Sites</strong></p>
            <p>This Service may contain links to other sites. If you click on a third-party link, you will be directed
              to that site. Note that these external sites are not operated by us. Therefore, we strongly
              advise you to review the Privacy Policy of these websites. We have no control over
              and assume no responsibility for the content, privacy policies, or practices of any third-party sites
              or services.
            </p>
            <p><strong>Children’s Privacy</strong></p>
            <p>These Services do not address anyone under the age of 13. We do not knowingly collect
              personally identifiable information from children under 13. In the case we discover that a child
              under 13 has provided us with personal information, we immediately delete this from
              our servers. If you are a parent or guardian and you are aware that your child has provided us with personal
              information, please contact us so that we will be able to do necessary actions.
            </p>
            <p><strong>Changes to This Privacy Policy</strong></p>
            <p> We may update our Privacy Policy from time to time. Thus, you are advised to review
              this page periodically for any changes. We will notify you of any changes by posting
              the new Privacy Policy on this page. These changes are effective immediately after they are posted on
              this page.
            </p>
            <p><strong>Contact Us</strong></p>
            <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact
              us. Email <a href="mailto:questions@eternalwarfare.org">questions@eternalwarfare.org</a>.
            </p>

            <h2>Credit</h2>
                <p><strong>Credit & Contact Information</strong></p>
                <p>This Terms and conditions page was created at <a
                    href="https://termsandconditionstemplate.com">termsandconditionstemplate.com</a> generator. If you have
                any queries regarding any of our terms, please contact us.</p>        


                <p>This privacy policy page was created at <a href="https://privacypolicytemplate.net" target="_blank" rel="noopener noreferrer">privacypolicytemplate.net</a>
                  and modified/generated by <a href="https://app-privacy-policy-generator.firebaseapp.com/" target="_blank" rel="noopener noreferrer">App
                    Privacy Policy Generator</a></p>
        </Col>
    </Row>
);

export default Terms;