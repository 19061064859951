export const rob2019pdx = {
    sku: "ROB2019PDX",
    name: "The Ruins Of Beverast - Portland",
    description: "The Ruins of Beverast - Grim Trees Ahead - North American Tour w/ DISPIRIT, Bedlamite",
    quantity: 1,
    price: "20.00",
    currency: "USD"
};

export const rob2019sea = {
    sku: "ROB2019SEA",
    name: "The Ruins Of Beverast - Seattle",
    description: "The Ruins of Beverast - Grim Trees Ahead - North American Tour w/ DISPIRIT, Nightfell",
    quantity: 1,
    price: "20.00",
    currency: "USD"
};

export const serviceCharge = {
    sku: "SERVICECHARGE",
    name: "Service Charge",
    description: "Service Charge",
    quantity: 1,
    price: "0.00",
    currency: "USD"
};

export const testItem = {
    sku: "TESTITEM",
    name: "Test Item",
    description: "Test Item - Test Item - Test Item",
    quantity: 1,
    price: "0.01",
    currency: "USD"
};

export const thechasm = {
    sku: "CHASM2019",
    name: "The Chasm - Portland, OR",
    description: "The Chasm, Cruciamentum, Infernal Conjuration, Autophagy, Solitude of Ravens",
    quantity: 1,
    price: "13.00",
    currency: "USD"
};

export const skus = {
    "ROB2019PDX": rob2019pdx,
    "ROB2019SEA": rob2019sea,
    "CHASM2019": thechasm,
    "TESTITEM": testItem
};