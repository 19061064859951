import React from "react";

const PaymentError = () => (
    <p className="info-text">
        There was an error. The payment ID you requested is not associated with a ticket you own. 
        If you think this is unexpected, please contact 
        <a href="mailto:eternalwarfarerecords@gmail.com">eternalwarfarerecords@gmail.com</a>.
    </p>
);

export default PaymentError;