import React, { Component } from 'react';
import Header from "./components/Header";
// eslint-disable-next-line
import styles from "./styles/index.css";
// eslint-disable-next-line
import bootstrap from "./styles/bootstrap.min.css";
import 'react-image-lightbox/style.css';
import Grid from "react-bootstrap/lib/Grid";
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { Link, Route, Switch } from "react-router-dom";
import Checkout from "./components/Checkout";
import Events from "./components/Events";
import ListTickets from "./components/ListTickets";
import Share from "./components/Share";
import Test from "./components/Test";
import Pay from "./components/Pay";
import PaymentSuccess from "./components/PaymentSuccess";
import PaymentError from "./components/PaymentError";
import Terms from "./components/Terms";
import PurchasedTickets from "./components/PurchasedTickets";
import RedeemTicket from "./components/RedeemTicket";
import ReactGA from "react-ga";
import cookies from "./util/cookies";

ReactGA.initialize("UA-132737908-1");

const Contact = () => <div><h2>Contact</h2><p className="info-text">You can send an email to <a href="mailto:eternalwarfarerecords@gmail.com">eternalwarfarerecords@gmail.com</a>. <br /> <br />We are NOT releasing physical media any more. I will usually listen to demo submissions but it's not going to get you anywhere!</p></div>;

const NotFound = () => <div><h2>Not Found</h2><p className="info-text">The page you requested does not exist.</p></div>;

//const Maintenance = () => <div className="maintenance-alert"><h4 className="alert-heading">Maintenance Notice</h4><p>The site is undergoing an update. Ticket purchases will be disabled until 11AM PST.</p></div>;

class App extends Component {
    render() {
        return (
            <div className="App">
                <div className="content">
                    <Header />
                    <div className="top-padding" />
                    {/*<Maintenance />*/}
                    <Grid className="Main">
                        <Row>
                            <Col xs={12} md={10} mdOffset={1}>
                                <Switch>
                                    <Route path="/" exact component={Events} />
                                    <Route path="/checkout" exact component={Checkout} />
                                    <Route path="/contact" exact component={Contact} />
                                    <Route path="/events" exact component={Events} />
                                    <Route path="/purchased-tickets" exact component={PurchasedTickets} />
                                    <Route path="/pay" exact component={Pay} />
                                    <Route path="/payment/success/:payId" exact component={PaymentSuccess} />
                                    <Route path="/payment/error" exact component={PaymentError} />
                                    <Route path="/share" exact component={Share} />
                                    <Route path="/terms" exact component={Terms} />
                                    <Route path="/test" exact component={Test} />

                                    { cookies.token && cookies.token.admin &&
                                        <Route path="/admin" exact component={ListTickets} />
                                    }

                                    { cookies.token && cookies.token.admin &&
                                        <Route path="/admin/redeem" exact component={RedeemTicket} />
                                    }

                                    { cookies.token && cookies.token.admin &&
                                        <Route path="/admin/list-tickets" exact component={ListTickets} />
                                    }

                                    <Route component={NotFound} />
                                </Switch>
                            </Col>
                        </Row>
                    </Grid>
                </div>
                <div className="footer">
                            <Link to="/terms" className="footer-link">Terms and Conditions</Link>
                            <Link to="/terms" className="footer-link">Privacy Policy</Link>
                            <a href="mailto:questions@eternalwarfare.org" className="footer-link">Contact</a>
                       
                </div>
            </div>
        );
    }
}

export default App;
