import React from 'react';
import ReactDOM from 'react-dom';
import paypal from 'paypal-checkout';
import { getItemsPlusServiceCharge } from "./Checkout";

export default class PaypalButton extends React.Component {


  render() {

    let { className, disabled, items, onSuccess, onCancel, onError } = this.props;

    let itemsPlusServiceCharge = getItemsPlusServiceCharge(items);

    let env = process.env.NODE_ENV === "production" ? "production" : "sandbox";

    let client = {
      sandbox: process.env.REACT_APP_PAYPAL_CLIENT_ID_SANDBOX,
      production: process.env.REACT_APP_PAYPAL_CLIENT_ID_PRODUCTION
    };

    const calculateTotal = (items) => {
      let total = 0;
      items.map(item => 
        total += (parseFloat(item.price)* item.quantity)
      );
      return Number.isInteger(total) ? (total.toString() + ".00") : total.toString();
    };

    let payment = (data, actions) => {
        return actions.payment.create({
            application_context: {
              brand_name: "Eternal Warfare",
              shipping_preference: "NO_SHIPPING"
            },
            transactions: [
                {
                    amount: {
                        total: calculateTotal(itemsPlusServiceCharge),
                        currency: 'USD'
                    },
                    description: "Eternal Warfare Fest 2019",
                    item_list: {
                      items: itemsPlusServiceCharge
                    }
                }
            ]
        });
    };

    let onAuthorize = (data, actions) => {
            return actions.payment.execute().then((response) => {
                onSuccess(data, response);
            });
        };

    let Button = paypal.Button.driver('react', { React, ReactDOM });

    return (
      <div style={{width:"100%"}}>
        <Button
          className={className}
          client={client}
          disabled={disabled}
          payment={payment}
          commit={true}
          onAuthorize={onAuthorize}
          onCancel={onCancel}
          onError={onError}
          env={env} />
      </div>
    );
  }
}
