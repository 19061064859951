import React from 'react';
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import PaypalButton from "./PaypalButton";
import Panel from "react-bootstrap/lib/Panel";
import { serviceCharge } from "../constants/tickets";

export const calcSubtotal = items => 
    items.map(item => item.price * item.quantity).reduce((prev, curr) => parseFloat((prev + curr).toFixed(2)));

export const calcServiceCharge = items => parseFloat((calcSubtotal(items) * 0.06).toFixed(2));

export const calcTotal = items => calcSubtotal(items) + calcServiceCharge(items);

export const getItemsPlusServiceCharge = items => [...items, {...serviceCharge, price: calcServiceCharge(items).toString()}]

const Checkout = withRouter(({history, items, ticketsAvailable, ticketPaymentSuccess, updateQuantity}) => {

    const onSuccess = (data, response) => {
        let id = response.id;
        let email = response.payer.payer_info.email;
        let firstName = response.payer.payer_info.first_name;
        let lastName = response.payer.payer_info.last_name;
        let name = firstName + " " + lastName;
        ticketPaymentSuccess(id, items, email, "eternal-warfare-purchased-ticket", name);
        window.scrollTo(0, 0);
        history.push("/payment/success/" + id);
    }

    const onError = (error) => {
        console.log('Erroneous payment OR failed to load script!', error);
        history.push("/payment/error");
    }

    const onCancel = (data) => {
        console.log('Cancelled payment!', data);
    }

    return (
        <div>
            { items.length > 0 && 
                <Row>
                    <Col xs={12}>
                        <h3>Checkout</h3>
                        { items.map(item => { 
                            let amountAvailable = ticketsAvailable[item.sku];
                            return (
                                <Row key={item.sku}>
                                    <Panel className="checkout-item">
                                        <Panel.Heading className="checkout-item-heading">
                                            <Panel.Title className="checkout-item-title">{item.name}</Panel.Title>
                                        </Panel.Heading>
                                        <Panel.Body className="checkout-item-body">
                                            <Row>
                                                <Col sm={7}>
                                                    {item.description}
                                                </Col>
                                                <Col sm={1}>
                                                    <DropdownButton 
                                                        id="number-of-test-items"
                                                        title={item.quantity}
                                                        onSelect={(value) => updateQuantity(item.sku, value)}>
                                                        <MenuItem eventKey="0">0</MenuItem>
                                                        <MenuItem eventKey="1">1</MenuItem>
                                                        { amountAvailable > 1 && <MenuItem eventKey="2">2</MenuItem> }
                                                        { amountAvailable > 2 && <MenuItem eventKey="3">3</MenuItem> }
                                                        { amountAvailable > 3 && <MenuItem eventKey="4">4</MenuItem> }
                                                    </DropdownButton>
                                                </Col>
                                                <Col sm={4} className="checkout-item-price">
                                                    ${item.price} each = <b>${ parseFloat(item.price) * item.quantity}</b>
                                                </Col>
                                            </Row>
                                        </Panel.Body>
                                    </Panel>
                                </Row>
                            )}
                        )}
                            
                        <Row>
                            <Panel className="checkout-item">
                                <Panel.Body className="checkout-item-body">
                                    <Row>
                                        <Col sm={7}>
                                            Subtotal
                                        </Col>
                                        <Col sm={4} smOffset={1} className="checkout-item-price">
                                            ${calcSubtotal(items).toFixed(2)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7}>
                                            Service charge
                                        </Col>
                                        <Col sm={4} smOffset={1} className="checkout-item-price">
                                            6% = ${calcServiceCharge(items).toFixed(2)}
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col sm={7}>
                                            <b>Total</b>
                                        </Col>
                                        <Col sm={4} smOffset={1} className="checkout-item-price">
                                            <b>${calcTotal(items).toFixed(2)}</b>
                                        </Col>
                                    </Row>
                                </Panel.Body>
                            </Panel>
                        </Row>
                        <PaypalButton
                            className="center"
                            items={items}
                            onSuccess={onSuccess}
                            onError={onError}
                            onCancel={onCancel}
                        />
                    </Col>
                </Row>
            }
            { items.length === 0 &&
                 <Row>
                    <Col xs={12}>
                        <h3>Checkout</h3>
                        <p className="info-text">You have no items in your cart!</p>
                    </Col>
                </Row>
            }
        </div>
    );
});

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    items: state.itemsInCart,
    ticketsAvailable: state.ticketsAvailable
  };
};

const mapDispatchToProps = dispatch => ({ 
    ticketPaymentSuccess: (payId, items, email, imageName, name) =>
        dispatch({
            type: "TICKET_PAYMENT_SUCCESS",
            payload: { payId, items, email, imageName, name }
        }),
    updateQuantity: (sku, quantity) => 
        dispatch({
            type: "UPDATE_QUANTITY",
            payload: {
                sku,
                quantity
            }
        })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Checkout);
