import React, { Component } from 'react';
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Nav from "react-bootstrap/lib/Nav";
import NavItem from "react-bootstrap/lib/NavItem";
import Ticket from "./Ticket";
import html2canvas from "html2canvas";
import { LinkContainer } from "react-router-bootstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class PaymentSuccess extends Component {

    constructor(props) {
        super(props);
        this.getPurchasedTicket = this.getPurchasedTicket.bind(this);
        this.prepareDownload = this.prepareDownload.bind(this);
    }

    componentDidMount() {
        if (this.getPurchasedTicket() && !this.getPurchasedTicket().qrCode) {
            this.prepareDownload();
        }
    }

    getPurchasedTicket() {
        let { match, tickets } = this.props;
        if (match.params.payId && tickets) {
            return tickets[match.params.payId] ? tickets[match.params.payId] : false;
        }
    }

    prepareDownload() {
        let { match, setQrCode } = this.props;
        let payId = match.params.payId;
        let ticketElement = document.querySelector("#" + payId);
        if (ticketElement) {
            html2canvas(ticketElement, {backgroundColor: "#000000"}).then(canvas => {
                let qrCode = canvas.toDataURL('image/png');
                setQrCode(payId, qrCode);
            });
        }
    }

    render() {
        let { match } = this.props;
        let payId = match.params.payId;
        let ticket = this.getPurchasedTicket();

        const downloadTicket = () => {
            let a = document.createElement('a');
            a.href = ticket.qrCode;
            a.download = ticket.imageName;
            a.click();
        }

        return (
            <div>
                { ticket && <div>
                    <Row>
                        <Col xs={12}>
                            <h2>Success!</h2>
                            <p className="info-text">Your payment was successfully processed. A ticket has been generated here which references your entire purchase. If you purchased entry to more than one event, they will all be included on this ticket. You can choose to download it and present it at the door for entry to any of the events. If instead you would like individual tickets, they have all been sent to your email. You can also access your tickets on this site at any time by visiting <Link to="/purchased-tickets">Purchased Tickets</Link>. If there are any problems or if you have any questions you can reach out to <a href="mailto:questions@eternalwarfare.org">questions@eternalwarfare.org</a>. </p>
                            <br />
                            <Col xs={12} sm={6}>
                                <Ticket payId={payId} ticket={ticket} />
                            </Col>
                            <Col xs={12} sm={6}>
                                <Nav bsStyle="pills" stacked>
                                    <NavItem eventKey={1} onClick={downloadTicket}>
                                        <h5>Download Ticket</h5>
                                    </NavItem>
                                    <LinkContainer to="/share">
                                        <NavItem eventKey={3}>
                                            <h5>Get Sharable Images</h5>
                                        </NavItem>
                                    </LinkContainer>
                                    <LinkContainer to="/events">
                                        <NavItem eventKey={4}>
                                            <h5>Back To Events</h5>
                                        </NavItem>
                                    </LinkContainer>
                                </Nav>
                            </Col>
                        </Col>
                    </Row>
                </div> }
                { !ticket && <p className="info-text">There was an error. The payment ID you requested is not associated with any tickets you own. If you think this is unexpected, please contact <a href="mailto:eternalwarfarerecords@gmail.com">eternalwarfarerecords@gmail.com</a>.</p> }
            </div>
        );
    }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    tickets: state.tickets
  };
};

const mapDispatchToProps = dispatch => ({ 
    setQrCode: (payId, qrCode) => dispatch({ type: "SET_QR_CODE", payload: { payId, qrCode }})
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentSuccess);

 