import React, { Component } from 'react';
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";

class Events extends Component {

  render() {
    return (
        <div>
          <Row>
            <Col xs={12} md={10} mdOffset={1} lg={10} lgOffset={1}>
              <h3>Update 2022</h3>
              <br />
              <p>This site is temporarily not in use. Visit <a href="https://www.instagram.com/_eternalwarfare_">the instagram</a> for event updates.</p>
              <br />
            </Col>
          </Row>
        </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
  };
};

const mapDispatchToProps = dispatch => ({

});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Events);
