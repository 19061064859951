import React, { Component } from 'react';
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import mizmor_roadburn_2020 from "../images/promo/mizmor_roadburn_2020.jpg";

class Share extends Component {
    render() {
        return (
            <div>
                <Row>
                    <Col xs={12}>
                    <h3>Share Images</h3>
                        <p className="info-text">Click an image below to download it for sharing. Tag us on instagram: <a href="https://www.instagram.com/_eternalwarfare_/">@_eternalwarfare_</a> or hashtag <a href="http://instagram.com/tags/eternalwarfarefest">#EternalWarfareFest</a>.</p>
                        <br />
                        <br />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={6}>
                        <a href={mizmor_roadburn_2020} download="mizmor_roadburn_2020.jpg">
                            <img src={mizmor_roadburn_2020} alt="Mizmor - Roadburn 2020" className="full-size-image" />
                        </a>
                        <br />
                        <br />
                    </Col>
                </Row>
            </div>
        );
  }
}

export default Share;
