import React from 'react';
import Panel from "react-bootstrap/lib/Panel";
import logo from "../images/eternal_warfare_logo.svg";
import { qrBaseUrl } from "../constants/api";

var QRCode = require("qrcode.react");

const Ticket = ({payId, ticket}) => {

    return (
            <Panel id={payId} className="ticket">
                <Panel.Heading className="ticket-heading">
                    <Panel.Title><img src={logo} className="full-size-image" alt="Eternal Warfare" /></Panel.Title>
                </Panel.Heading>
                <Panel.Body className="ticket-body">
                    <p><b>Eternal Warfare Event Ticket</b></p>
                    <p>Ticket holder: <b>{ticket.name}</b></p>
                    { ticket.items && ticket.items.map(item => 
                        <div key={item.sku}>
                            <p> <b>{item.name}</b> </p>
                            <p> Quantity purchased: <b>{item.quantity}</b> </p>
                            <p> {item.description} </p>
                        </div>)}
                    <p className="qr-container"><QRCode value={qrBaseUrl + payId} size={256} className="ticket" /></p>
                </Panel.Body>
            </Panel>
    );
}

export default Ticket;