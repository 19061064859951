import React, { Component } from 'react';
import Navbar from "react-bootstrap/lib/Navbar";
import Nav from "react-bootstrap/lib/Nav";
import NavItem from "react-bootstrap/lib/NavItem";
import Dropdown from "react-bootstrap/lib/Dropdown";
import DropdownMenu from "react-bootstrap/lib/DropdownMenu";
import DropdownToggle from "react-bootstrap/lib/DropdownToggle";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import ModalBody from "react-bootstrap/lib/ModalBody";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import logo from "../images/eternal_warfare_logo.svg";
import facebook from "../images/facebook.svg";
import instagram from "../images/instagram.svg";
import bandcamp from "../images/bandcamp.svg";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import packageJson from "../../package.json";
import cookies from "../util/cookies";

class Header extends Component {

  render() {
    return (
        <div>
          { this.props.showHeader && <Navbar collapseOnSelect fixedTop={true}>
            <img src={logo} alt={"Eternal Warfare v" + packageJson.version} className="eternal-warfare-logo" />
            <Navbar.Header>
              <Navbar.Brand>
                <img src={logo} alt={"Eternal Warfare v" + packageJson.version } className="eternal-warfare-brand" />
              </Navbar.Brand>
              <Navbar.Toggle />
            </Navbar.Header>
            <Navbar.Collapse>
              <Nav>
                <NavItem eventKey={1} componentClass={Link} href="/events" to="/events">
                  Events
                </NavItem>
                <NavItem eventKey={2} componentClass={Link} href="/pay" to="/pay">
                  Pay
                </NavItem>
                <NavItem eventKey={3} componentClass={Link} href="/contact" to="/contact">
                  Contact
                </NavItem>
              </Nav>
              <Nav pullRight>
                { cookies.token && <Dropdown className="account-dropdown" id="account-dropdown">
                  <DropdownToggle variant="success" id="dropdown-basic">
                    Account
                  </DropdownToggle>
                  <DropdownMenu>
                    <NavItem className="account-dropdown-link">
                      Logged in as { cookies.token.email }
                    </NavItem>
                    { !cookies.token.admin && 
                      <NavItem eventKey={6} componentClass={Link} href="/purchased-tickets" to="/purchased-tickets" className="account-dropdown-link">
                        Purchased Tickets
                      </NavItem> 
                    }
                    { cookies.token.admin && 
                      <NavItem eventKey={7} componentClass={Link} href="/admin" to="/admin" className="account-dropdown-link">
                        Admin Section
                      </NavItem> 
                    }
                    { cookies.hasBeenAdmin && !cookies.token.admin &&
                      <li role="presentation" className="account-dropdown-link">
                        <a href="https://eternalwarfare.org/adminlogin.html">
                          Admin Log In
                        </a>
                      </li>
                    }
                    { cookies.token.admin && 
                      <li role="presentation" className="account-dropdown-link">
                        <a href="https://eternalwarfare.org/login.html">
                          Customer Log In
                        </a>
                      </li>
                    }
                    <NavItem eventKey={10} className="account-dropdown-link" onClick={() => cookies.logout()}>
                      Log Out
                    </NavItem>
                  </DropdownMenu>
                </Dropdown> }
                { false && !cookies.token &&
                  <a eventKey={11} href="https://eternalwarfare.org/login.html" className="login-link">
                    Log In
                  </a>
                }
                { cookies.hasBeenAdmin && !cookies.token && 
                  <a eventKey={12} href="https://eternalwarfare.org/adminlogin.html" className="login-link">
                    Admin Log In
                  </a>
                }
                <NavItem eventKey={13} href="https://www.instagram.com/_eternalwarfare_/" className="header-icon">
                  <img src={instagram} alt="Instagram" />
                </NavItem>
                <NavItem eventKey={14} href="https://www.facebook.com/pages/Eternal-Warfare-Records/715982155106975" className="header-icon">
                  <img src={facebook} alt="Facebook" />
                </NavItem>
                <NavItem eventKey={15} href="https://eternalwarfare.bandcamp.com/" className="header-icon">
                  <img src={bandcamp} alt="Bandcamp" width="27" height="27" />
                </NavItem>
              </Nav>
            </Navbar.Collapse>
          </Navbar> }
          { this.props.error && <Modal show={this.props.error ? true : false}>
            <ModalHeader className="modal-header">
              An Error Occurred!
            </ModalHeader>
            <ModalBody className="modal-body">
              <Row>
                <Col xs={12}>
                  { this.props.error && this.props.toString && this.props.error.toString() }<br /><br />
                  { this.props.error && JSON.stringify(this.props.error) }
                  
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button className="modal-button" onClick={() => this.props.resetError()}>Close</Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal> }
          { this.props.success && <Modal show={this.props.success ? true : false}>
            <ModalHeader className="modal-header">
              Success!
            </ModalHeader>
            <ModalBody className="modal-body">
              <Row>
                <Col xs={12}>
                  { this.props.success }
                  
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Button className="modal-button" onClick={() => this.props.resetSuccess()}>Close</Button>
                </Col>
              </Row>
            </ModalBody>
          </Modal> }
        </div>
    );
  }
}

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    error: state.error,
    showHeader: state.showHeader,
    success: state.success
  };
};

const mapDispatchToProps = dispatch => ({
  resetError: () => dispatch({ type: "RESET_ERROR" }),
  resetSuccess: () => dispatch({ type: "RESET_SUCCESS" })
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);
