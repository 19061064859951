import { testItem, rob2019pdx, rob2019sea, thechasm } from "../constants/tickets";

const initialState = {
    error: null,
    fetching: false,
    fetchingTicket: false,
    fields: {
        email: "",
        name: "",
        searchName: ""
    },
    itemsInCart: [],
    purchasedItems: null,
    purchasesDisabled: false,
    scannedTicket: null,
    selectedEvent: null,
    selectedTickets: null,
    showConfirmation: false,
    showHeader: true,
    showModal: false,
    showQrReader: true,
    success: null,
    tickets: {
        // [PAYID]: {
        //     items: [threeDayTicket, rob2019pdx],
        //     email: "admin@eternalwarfare.org",
        //     imageName: "eternal-warfare-purchased-ticket",
        //     name: "Test Buyer",
        //     qrCode: null
        // }
    },
    ticketsAvailable: {
        [testItem.sku]: null,
        [rob2019pdx.sku]: null,
        [rob2019sea.sku]: null,
        [thechasm.sku]: null
    }
}

const rootReducer = (state = initialState, action) => {

  switch (action.type) {

    case "ADD_ITEM_TO_CART": {
        let index = state.itemsInCart.findIndex(item => item.sku === action.payload.item.sku);
        if (index === -1) {
            return {
                ...state,
                itemsInCart: [...state.itemsInCart, action.payload.item]
            };
        } else {
            if(state.itemsInCart[index].quantity < 4) {
                let itemsInCart = [...state.itemsInCart];
                itemsInCart[index].quantity += 1;
                return {
                    ...state,
                    itemsInCart
                };
            } else {
                return state;
            }
        }
    }
    case "HIDE_CONFIRMATION": {
        return {
            ...state,
            showConfirmation: false
        }
    }
    case "HIDE_HEADER": {
        return {
            ...state,
            showHeader: false
        }
    }
    case "HIDE_MODAL": {
        return {
            ...state,
            showModal: false
        }
    }
    case "HIDE_QR_READER": {
        return {
            ...state,
            showQrReader: false
        }
    }
    case "REMOVE_PURCHASED_ITEM": {
        let newItems = [...state.purchasedItems];
        let index = newItems.findIndex(item => item.item_id === action.payload.item_id);
        if (index !== -1) {
            newItems.splice(index, 1);
        }
        return {
            ...state,
            purchasedItems: newItems
        }
    }
    case "RESET_ERROR": {
        return {
            ...state,
            error: null
        }
    }
    case "RESET_SUCCESS": {
        return {
            ...state,
            success: null
        }
    }
    case "SET_FETCHING_TICKET": {
        return {
            ...state,
            fetchingTicket: true
        }
    }
    case "SET_QR_CODE": {
        return {
            ...state,
            tickets: {
                ...state.tickets,
                [action.payload.payId]:
                {
                    ...state.tickets[action.payload.payId],
                    qrCode: action.payload.qrCode
                }
            }
        }
    }
    case "SET_SCANNED_TICKET": {
        return {
            ...state,
            fetchingTicket: false,
            scannedTicket: action.payload.ticket
        }
    }
    case "SET_SELECTED_EVENT": {
        return {
            ...state,
            selectedEvent: action.payload.event
        }
    }
    case "SET_TICKET_LIST": {
        return {
            ...state,
            fetchingTicket: false,
            selectedTickets: action.payload.tickets
        }
    }
    case "SHOW_ERROR": {
        return {
            ...state,
            error: action.payload.error
        }
    }
    case "SHOW_CONFIRMATION": {
        return {
            ...state,
            showConfirmation: true
        }
    }
    case "SHOW_HEADER": {
        return {
            ...state,
            showHeader: true
        }
    }
    case "SHOW_MODAL": {
        return {
            ...state,
            showModal: true
        }
    }
    case "SHOW_QR_READER": {
        return {
            ...state,
            showQrReader: true
        }
    }
    case "SHOW_SUCCESS": {
        return {
            ...state,
            success: action.payload.success
        }
    }
    case "TICKET_PAYMENT_SUCCESS": {
        return {
            ...state,
            itemsInCart: [],
            tickets: {
                ...state.tickets,
                [action.payload.payId]: {
                    items: action.payload.items,
                    email: action.payload.email,
                    imageName: action.payload.imageName,
                    name: action.payload.name,
                    qrCode: null
                }
            }
        }
    }
    case "UPDATE_FETCHING": {
        return {
            ...state,
            fetching: action.payload.fetching
        }
    }
    case "UPDATE_FIELD": {
        return {
            ...state,
            fields: {
                ...state.fields,
                [action.payload.event.target.name]: action.payload.event.target.value
            }
        }
    }
    case "UPDATE_TICKET_AMOUNT": {
        return {
            ...state,
            ticketsAvailable: {
                ...state.ticketsAvailable,
                [action.payload.sku]: action.payload.amount
            }
        }
    }
    case "UPDATE_QUANTITY": {
        let itemsInCart = [...state.itemsInCart];
        let index = itemsInCart.findIndex(item => item.sku === action.payload.sku);
        if (index !== -1) {
            if (action.payload.quantity === "0") {
                itemsInCart.splice(index, 1);
            } else {
                itemsInCart[index].quantity = parseInt(action.payload.quantity);
            }
        }
        return {
            ...state,
            itemsInCart
        }
    }
    case "UPDATE_MY_TICKETS": {
        return {
            ...state,
            purchasedItems: action.payload.tickets
        }
    }
    default: {
        return state;
    }
  }
};

export default rootReducer;
