import Cookies from "universal-cookie";

const cookiesLib = new Cookies();
let token = null;
let hasBeenAdmin = false;

if (cookiesLib.get('ewt')) {
    try {
        let token64 = cookiesLib.get('ewt').split('.')[1];
        let tokenJson = window.atob(token64);
        token = JSON.parse(tokenJson);
    } catch (err) {
        console.log("Invalid token", err);
    }
};

if (cookiesLib.get('ewa')) {
    hasBeenAdmin = true;
}

const logout = () => {
    cookiesLib.set('ewt', '', {expires: new Date(), maxAge: 0, domain: ".eternalwarfare.org"});
    window.location.replace("https://eternalwarfare.org");
}

const cookies = {
    hasBeenAdmin,
    logout,
    token
};

export default cookies;
