import React from 'react';
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import Button from "react-bootstrap/lib/Button";
import Table from "react-bootstrap/lib/Table";
import Nav from "react-bootstrap/lib/Nav";
import NavItem from "react-bootstrap/lib/NavItem";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import ModalBody from "react-bootstrap/lib/ModalBody";
import Modal from "react-bootstrap/lib/Modal";
import { LinkContainer } from "react-router-bootstrap";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";   
import { testItem } from "../constants/tickets";
import axios from "axios";
import { ticketsAvailableBaseUrl } from "../constants/api";

const Test = withRouter(({history, items, modalOpen, ticketsAvailable, addItemToCart, hideModal, showError, showModal, updateTicketAmount}) => {

    [testItem.sku].forEach(sku => {
      if (ticketsAvailable[sku] === null) {
        if (process.env.NODE_ENV === "production") {
            axios.get(ticketsAvailableBaseUrl + sku)
            .then(response => 
              updateTicketAmount(sku, response.data)
            ).catch((err) => {
                console.log(err);
                showError(err);
            });
        } else {
            updateTicketAmount(sku, 4);
        }
      }
    });

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h2>Test Item</h2>
                    <p className="info-text">Test Item - Test Item - Test Item</p>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Table striped bordered condensed hover>
                      <thead>
                        <tr>
                          <th className="info-text">Tickets</th>
                          <th className="info-text">Price Each</th>
                          <th className="info-text">Add to cart</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td className="info-text">Test Item</td>
                          <td className="info-text">$0.01</td>
                          <td className="info-text">
                            { ticketsAvailable[testItem.sku] !== null && ticketsAvailable[testItem.sku] > 0 &&
                                <Button onClick={ () => {
                                        addItemToCart(testItem);
                                        showModal();
                                    } }>Add to cart</Button>
                            }
                            { ticketsAvailable[testItem.sku] === null && <p>Loading...</p> }
                            { ticketsAvailable[testItem.sku] !== null && ticketsAvailable[testItem.sku] <= 0 && <p><b>Sold out</b></p> }
                          </td>
                        </tr>
                      </tbody>
                    </Table>
                </Col>
            </Row>
            <Col xs={12} sm={6}>
                <Nav bsStyle="pills" stacked>
                    <NavItem eventKey={2} href="https://www.facebook.com/events/420394438495637/">
                        <h5>Facebook Event Page</h5>
                    </NavItem>
                    <LinkContainer to="/share">
                        <NavItem eventKey={3}>
                            <h5>Get Sharable Images</h5>
                        </NavItem>
                    </LinkContainer>
                </Nav>
            </Col>
            <Modal show={modalOpen}>
                <ModalHeader className="modal-header">
                    Item added to cart!
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row>
                        <Col xs={6}>
                            <Button 
                                className="modal-button" 
                                onClick={ () => hideModal() }>
                                Stay on this page
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button 
                                className="modal-button" 
                                onClick={
                                    () => {
                                        hideModal();
                                        history.push("/checkout");
                                    }
                                }>
                                    Continue to cart
                                </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
});

const mapStateToProps = (state /*, ownProps*/) => {
  return {
    items: state.itemsInCart,
    modalOpen: state.showModal,
    ticketsAvailable: state.ticketsAvailable
  };
};

const mapDispatchToProps = dispatch => ({ 
    addItemToCart: (item) => dispatch({ type: "ADD_ITEM_TO_CART", payload: { item } } ),
    hideModal: () => dispatch({type: "HIDE_MODAL"}),
    showError: (error) => dispatch({ type: "SHOW_ERROR", payload: {error}}),
    showModal: () => dispatch({type: "SHOW_MODAL"}),
    updateTicketAmount: (sku, amount) => dispatch({ type: "UPDATE_TICKET_AMOUNT", payload: { sku, amount }})
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Test);

