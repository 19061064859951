import React from 'react';
import Row from "react-bootstrap/lib/Row";
import Col from "react-bootstrap/lib/Col";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { myTickets, transferTicket } from "../constants/api";
import Ticket from "./Ticket";
import * as ticketConstants from "../constants/tickets";
import Cookies from 'universal-cookie';
import Button from "react-bootstrap/lib/Button";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import ModalBody from "react-bootstrap/lib/ModalBody";
import Modal from "react-bootstrap/lib/Modal";
const cookies = new Cookies();

const MyTickets = withRouter(({confirmationOpen, email, fetchingTickets, hideConfirmation, hideModal, history, modalOpen, name, removeTicket, scannedTicket, setScannedTicket, showConfirmation, showError, showModal, showSuccess, tickets, updateFetchingTickets, updateField, updateMyTickets}) => {

    if(tickets === null) {
        updateFetchingTickets(true);
        if (process.env.NODE_ENV === "production") {
            let token = cookies.get('ewt');
            if(!token) {
                window.location.href = 'https://eternalwarfare.org/login.html';
            }
            axios.get(myTickets, {
                headers: {
                    Authorization: 'Bearer ' + token 
                }
            }).then(response => {
              updateMyTickets(response.data);
              updateFetchingTickets(false);
            }).catch(error => {
                console.log(error);
                window.location.href = 'https://eternalwarfare.org/login.html';
            });
        } else {
            updateMyTickets([{
                    "id": 2,
                    "item_id": "ITEMID-xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx",
                    "pay_id": "PAYID-XXXXXXXXXXXXXXXXXXXXXXXXXXXX",
                    "sku": "TESTITEM",
                    "quantity": "4",
                    "email": "test@eternalwarfare.org",
                    "name": "Test Buyer",
                    "redeemed": 0
                },
                {
                    "id": 1,
                    "item_id": "ITEMID-xxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxy",
                    "pay_id": "PAYID-XXXXXXXXXXXXXXXXXXXXXXXXXXXY",
                    "sku": "TESTITEM",
                    "quantity": "1",
                    "email": "test@eternalwarfare.org",
                    "name": "Test Buyer"
                }]);
            updateFetchingTickets(false);
        }
    }

    let scannedEvent = scannedTicket && ticketConstants.skus[scannedTicket.sku];

    return (
        <div>
            <h2>Purchased Tickets</h2>
            { fetchingTickets && 
                <Row>
                    <Col sm={12}>
                        <h4>Loading...</h4>
                    </Col>
                </Row>
            }
            { tickets && tickets.filter(ticket => parseInt(ticket.redeemed) !== 1).length === 0 && 
                <Row>
                    <Col sm={12}>
                        <div className="info-text">
                            You have no purchased tickets!
                        </div>
                    </Col>
                </Row>
            }
            { tickets && tickets.filter(ticket => parseInt(ticket.redeemed) !== 1).map((ticket, index) => {
                let event = ticketConstants.skus[ticket.sku];
                return <Row key={index}>
                    <Col sm={12}>
                        <h4> { event.name } <div className="transfer-ticket-button"><button className="btn btn-default btn-sm" onClick={() => {
                            setScannedTicket(ticket);
                            showModal();
                        }}>(Transfer to someone else)</button></div> </h4>
                        <Ticket 
                            payId={ticket.pay_id} 
                            ticket={{
                                name: ticket.name,
                                items: [{
                                    description: event.description,
                                    name: event.name,
                                    quantity: ticket.quantity,
                                    sku: ticket.sku
                                }]
                            }} />
                    </Col>
                </Row>
            })}
            <Modal show={modalOpen && !confirmationOpen}>
                <ModalHeader className="modal-header">
                    Transfer ticket(s) to someone else
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row className="transfer-ticket-input-row">
                        <Col xs={12}>
                            <p className="info-text">
                                Please note at this time a valid PayPal email is required to log in and retrieve a ticket.
                            </p>
                        </Col>   
                    </Row>
                    <Row className="transfer-ticket-input-row">
                        <Col xs={12} sm={4}>
                            <p className="info-text">
                                Email
                            </p>
                        </Col>
                        <Col xs={12} sm={8}>
                            <input className="input transfer-ticket-input" type="text" name="email" onChange={updateField} value={email} />
                        </Col>    
                    </Row>
                    <Row className="transfer-ticket-input-row">
                        <Col xs={12} sm={4}>
                            <p className="info-text">
                                Name
                            </p>
                        </Col>
                        <Col xs={12} sm={8}>
                            <input className="input transfer-ticket-input" type="text" name="name" onChange={updateField} value={name} />
                        </Col>    
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Button 
                                className="modal-button" 
                                onClick={ () => {
                                    hideModal();
                                    setScannedTicket(null);
                                    updateField({target: {name: "name", value: ""}});
                                    updateField({target: {name: "email", value: ""}});
                                } }>
                                Cancel
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button 
                                className="modal-button" 
                                disabled={name !== "" && email !== "" ? false : true}
                                onClick={ () => {
                                    showConfirmation();
                                } }>
                                Save
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
            <Modal show={confirmationOpen}>
                <ModalHeader className="modal-header">
                    Confirmation
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row className="transfer-ticket-input-row">
                        <Col xs={12}>
                            <p className="info-text">
                                Please confirm you want to transfer <b>{scannedTicket && scannedTicket.quantity}</b> ticket(s) for <b>{scannedEvent && scannedEvent.name}</b> to: 
                            </p>
                        </Col>  
                    </Row>
                    <Row className="transfer-ticket-input-row">
                        <Col xs={12} sm={4}>
                            <p className="info-text">
                                Email
                            </p>
                        </Col>
                        <Col xs={12} sm={8}>
                            { email }
                        </Col>    
                    </Row>
                    <Row className="transfer-ticket-input-row">
                        <Col  xs={12} sm={4}>
                            <p className="info-text">
                                Name
                            </p>
                        </Col>
                        <Col  xs={12} sm={8}>
                            { name }
                        </Col>    
                    </Row>
                    <Row>
                        <Col xs={6}>
                            <Button 
                                className="modal-button" 
                                onClick={ () => {
                                    hideConfirmation();
                                } }>
                                Back
                            </Button>
                        </Col>
                        <Col xs={6}>
                            <Button 
                                className="modal-button" 
                                onClick={ () => {
                                    updateFetchingTickets(true);
                                    hideConfirmation();
                                    hideModal();
                                    updateField({target: {name: "name", value: ""}});
                                    updateField({target: {name: "email", value: ""}});
                                    if (process.env.NODE_ENV === "production") {
                                        let token = cookies.get('ewt');
                                        if(!token) {
                                            window.location.href = 'https://eternalwarfare.org/login.html';
                                        }
                                        axios.put(transferTicket, 
                                        {
                                            email,
                                            item_id: scannedTicket.item_id,
                                            name, 
                                        }, 
                                        {
                                            headers: {
                                                Authorization: 'Bearer ' + token 
                                            }
                                        }).then(response => {
                                            removeTicket(scannedTicket.item_id);
                                            updateFetchingTickets(false);
                                            setScannedTicket(null);
                                            showSuccess("Ticket successfully transferred!");
                                        }).catch(error => {
                                            console.log(error);
                                            showError("There was a problem transferring your ticket.");
                                        });
                                    } else {
                                        removeTicket(scannedTicket.item_id);
                                        updateFetchingTickets(false);
                                        setScannedTicket(null);
                                        showSuccess("Ticket successfully transferred!");
                                    }
                                } }>
                                Confirm
                            </Button>
                        </Col>
                    </Row>
                </ModalBody>
            </Modal>
        </div>
    );
});

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        confirmationOpen: state.showConfirmation,
        fetchingTickets: state.fetchingTickets,
        modalOpen: state.showModal,
        tickets: state.purchasedItems,
        name: state.fields.name,
        email: state.fields.email,
        scannedTicket: state.scannedTicket
    };
};

const mapDispatchToProps = dispatch => ({ 
    hideConfirmation: () => dispatch({type: "HIDE_CONFIRMATION"}),
    hideModal: () => dispatch({type: "HIDE_MODAL"}),
    removeTicket: (item_id) => dispatch({type: "REMOVE_PURCHASED_ITEM", payload: { item_id }}),
    setScannedTicket: (ticket) => dispatch({type: "SET_SCANNED_TICKET", payload: { ticket }}),
    showConfirmation: () => dispatch({type: "SHOW_CONFIRMATION"}),
    showError: (error) => dispatch({type: "SHOW_ERROR", payload: { error }}),
    showModal: () => dispatch({type: "SHOW_MODAL"}),
    showSuccess: (success) => dispatch({type: "SHOW_SUCCESS", payload: { success }}),
    updateFetchingTickets: (fetching) => dispatch({ type: "UPDATE_FETCHING", payload: { fetching }}),
    updateField: (event) => dispatch({ type: "UPDATE_FIELD", payload: { event }}),
    updateMyTickets: (tickets) => dispatch({ type: "UPDATE_MY_TICKETS", payload: { tickets }})
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MyTickets);

