let host = window.location.protocol + '//' + window.location.hostname;

if (host !== "https://eternalwarfare.org" && host !== "https://www.eternalwarfare.org" && host !== "https://test.eternalwarfare.org") {
    host = "https://eternalwarfare.org";
}

export const qrBaseUrl = host + "/api/v1/ticket/verify/";
export const qrFestBaseUrl = host + "/api/fest/2019/ticketing/";
export const ticketsAvailableBaseUrl = host + "/api/v1/ticket/available/";
export const listTickets = host + "/api/v1/tickets/event/";
export const myTickets = host + "/api/v1/tickets";
export const ticket = host + "/api/v1/ticket/";
export const transferTicket = host + "/api/v1/ticket/transfer";
export const redeemTicket = host + "/api/v1/ticket/redeem/";