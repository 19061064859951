import React from "react";
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import DropdownButton from "react-bootstrap/lib/DropdownButton";
import MenuItem from "react-bootstrap/lib/MenuItem";
import Table from "react-bootstrap/lib/Table";
import Button from "react-bootstrap/lib/Button";
import { connect } from "react-redux";
import axios from "axios";
import * as ticketConstants from "../constants/tickets";
import { listTickets } from "../constants/api";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom"; 
let cookies = new Cookies();

const ListTickets = withRouter(({clearSearchInput,
    history,
    fetchingTicket, 
    hideQrReader,
    searchName,
    selectedEvent,
    selectedTickets,
    setFetchingTicket, 
    setScannedTicket,
    setSelectedEvent,
    setTicketList,
    showError, 
    showModal,
    showSuccess,
    updateField}) => {

    const selectEvent = (sku) => {
        let token = cookies.get('ewt');
        if(!token) {
            console.log("No token!");
            window.location.href = 'https://eternalwarfare.org/adminlogin.html';
        }
        setSelectedEvent(sku);
        setFetchingTicket();
        let listTicketsWithSku = listTickets + sku;
        if (process.env.NODE_ENV === "production") {
            axios.get(listTicketsWithSku, {
                headers: {
                    Authorization: 'Bearer ' + token 
                }
            }).then(response => {
                setTicketList(response.data);
            }).catch(err => {
                console.log(err);
                setTicketList(null);
                showError(err);
            });
        } else {
            setTicketList([{
                name: "Test User",
                email: "somelongemailthing@gmail.com",
                quantity: 1,
                item_id: "xxxxxxxx",
                redeemed: "1",
                notes: "Guest list"
            },{
                name: "Some Guy",
                email: "somelongemailthing@gmail.com",
                quantity: 2,
                item_id: "xxxxxxxx",
                redeemed: 0
            }]);
        }
    }

    return (<div>
        <Row>
            <Col xs={12}>
                <h2>List Tickets</h2>
                <Button className="btn will-call-btn" onClick={() => history.push("/admin/redeem")}>
                    Ticket Scanner
                </Button>
                <p className="info-text">
                    Event:
                    <div className="select-event-dropdown">
                        <DropdownButton 
                        title={selectedEvent ? ticketConstants.skus[selectedEvent].name : "Select an event"}
                            onSelect={(value) => {
                                selectEvent(value);
                                clearSearchInput();
                            }}>
                            { ["ROB2019PDX", "ROB2019SEA", "CHASM2019"].map((sku) => <MenuItem eventKey={sku}>{ ticketConstants.skus[sku].name }</MenuItem>)}
                        </DropdownButton>
                    </div>
                </p>
                
                { fetchingTicket && <p className="info-text">Loading...</p> }

                { !fetchingTicket && selectedTickets && selectedTickets.length > 0 &&
                    <p className="info-text">
                        Tickets sold: {selectedTickets.map(ticket => ticket.quantity).reduce((prev, curr, ) => (parseInt(prev) + parseInt(curr)))}
                    </p>
                }

                { !fetchingTicket && selectedTickets && selectedTickets.length === 0 &&
                    <p className="info-text">
                        Tickets sold: 0
                    </p>
                }

                { !fetchingTicket && selectedTickets && selectedTickets.length > 0 &&
                    <div className="search-names-input-row">
                        <label for="searchName">Search</label>
                        <input 
                            type="text" 
                            name="searchName" 
                            className="input search-names-input"
                            onChange={updateField} 
                            value={searchName}/>
                        <Button
                            onClick={clearSearchInput}>
                            Clear
                        </Button>
                    </div>
                }

                <div className="tickets-list">
                    { !fetchingTicket && selectedTickets && 
                        <Table striped bordered condensed hover>
                            <thead>
                                <tr>
                                    <th className="info-text">Name</th>
                                    <th className="info-text">Scan</th>
                                    <th className="info-text">Quantity</th>
                                    <th className="info-text">Redeemed</th>
                                    <th className="info-text">Email</th>
                                    <th className="info-text">Notes</th>
                                </tr>
                            </thead>
                            <tbody>
                                { selectedTickets.sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
                                    .filter(ticket => searchName === "" || ticket.name.toLowerCase().includes(searchName.toLowerCase()))
                                    .map(ticket => 
                                    <tr>
                                        <td className="info-text">{ticket.name}</td>
                                        <td className="info-text">
                                            <button
                                                className="btn btn-default btn-sm"
                                                onClick={() => {
                                                    clearSearchInput();
                                                    setFetchingTicket();
                                                    hideQrReader();
                                                    showModal();
                                                    setScannedTicket([ticket]);
                                                    history.push("/admin/redeem");
                                                }}>
                                                Scan
                                            </button>
                                        </td>
                                        <td className="info-text">{ticket.quantity}</td>
                                        <td className="info-text">{parseInt(ticket.redeemed) === 1 ? "Yes" : "No"}</td>
                                        <td className="info-text">{ticket.email}</td>
                                        <td className="info-text">{ticket.notes ? ticket.notes : ""}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    }
                </div>
            </Col>
        </Row>
    </div>);
});

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        fetchingTicket: state.fetchingTicket,
        searchName: state.fields.searchName,
        selectedEvent: state.selectedEvent,
        selectedTickets: state.selectedTickets
    };
};

const mapDispatchToProps = dispatch => ({ 
    clearSearchInput: () => dispatch({ type: "UPDATE_FIELD", payload: { event: { target: { name: "searchName", value: ""}}}}),
    hideQrReader: () => dispatch({ type: "HIDE_QR_READER" }),
    setFetchingTicket: () => dispatch({ type: "SET_FETCHING_TICKET" }),
    setScannedTicket: (ticket) => dispatch({type: "SET_SCANNED_TICKET", payload: {ticket}}),
    setSelectedEvent: (sku) => dispatch({ type: "SET_SELECTED_EVENT", payload: { event: sku }}),
    setTicketList: (tickets) => dispatch({ type: "SET_TICKET_LIST", payload: { tickets }}),
    showError: (error) => dispatch({ type: "SHOW_ERROR", payload: { error }}),
    showModal: () => dispatch({type: "SHOW_MODAL"}),
    showSuccess: (success) => dispatch({ type: "SHOW_SUCCESS", payload: { success }}),
    updateField: (event) => dispatch({ type: "UPDATE_FIELD", payload: { event }}),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListTickets);

