import React from "react";
import QrReader from 'react-qr-reader';
import Col from "react-bootstrap/lib/Col";
import Row from "react-bootstrap/lib/Row";
import ModalHeader from "react-bootstrap/lib/ModalHeader";
import ModalBody from "react-bootstrap/lib/ModalBody";
import Modal from "react-bootstrap/lib/Modal";
import Button from "react-bootstrap/lib/Button";
import { connect } from "react-redux";
import axios from "axios";
import xss from "xss";
import * as ticketConstants from "../constants/tickets";
import { ticket as ticketEndpoint } from "../constants/api";
import { redeemTicket as redeemTicketEndpoint } from "../constants/api";
import Cookies from "universal-cookie";
import { withRouter } from "react-router-dom"; 
let cookies = new Cookies();

class QrReaderWrapper extends React.Component {
    constructor(props){
        super(props);
        this.openImageDialog = this.openImageDialog.bind(this);
        this.iOS = /^(iPhone|iPad|iPod)/.test(navigator.platform);
    }

    openImageDialog() {
        this.refs.qrReader.openImageDialog();
    }

    render() {
        return <div onClick={() => this.iOS ? this.openImageDialog() : () => {}}>
                <QrReader
                    delay={this.props.delay}
                    legacyMode={this.iOS ? true : false}
                    onError={this.props.onError}
                    onScan={(result) => this.props.onScan(result)}
                    ref="qrReader"
                    style={{ width: '100%' }}
                />
            </div>;
    }
}

const RedeemTicket = withRouter(({history, fetchingTicket, hideModal, hideQrReader, modalOpen, qrReaderVisible, scannedTicket, setScannedTicket, setFetchingTicket, showError, showModal, showQrReader, showSuccess}) => {

    const handleError = (error) => {
        console.log(error);
    }

    const handleScan = (ticketUrl) => {
        if(ticketUrl) {
            let token = cookies.get('ewt');
            if(!token) {
                console.log("No token!");
                window.location.href = 'https://eternalwarfare.org/adminlogin.html';
            }
            let ticketUrlSplit = ticketUrl.split("/");
            let id = ticketUrlSplit[ticketUrlSplit.length-1];
            let ticketEndpointWithId = ticketEndpoint;
            if (id.split("ID-")[0] === "PAY") {
                ticketEndpointWithId += "?payid=" + xss(id);
            } else {
                ticketEndpointWithId += "?itemid=" + xss(id);
            }
            setFetchingTicket();
            hideQrReader();
            showModal();
            axios.get(ticketEndpointWithId, {
                headers: {
                    Authorization: 'Bearer ' + token 
                }
            }).then(response => {
                setScannedTicket(response.data);
            }).catch(err => {
                console.log(err);
                hideModal();
                showQrReader();
                setScannedTicket(null);
                showError(err);
            });
        }
    }

    const redeemTicket = (index) => {
        if (scannedTicket[index]) {
            let token = cookies.get('ewt');
            if(!token) {
                console.log("No token!");
                window.location.href = 'https://eternalwarfare.org/adminlogin.html';
            }
            setFetchingTicket();
            axios.post(redeemTicketEndpoint, 
            {
                item_id: xss(scannedTicket[index].item_id)
            },
            {
                headers: {
                    Authorization: 'Bearer ' + token 
                }
            }).then(response => {
                hideModal();
                showQrReader();
                setScannedTicket(null);
                showSuccess("Ticket successfully redeemed.");
            }).catch(err => {
                console.log(err);
                hideModal();
                showQrReader();
                setScannedTicket(null);
                showError(err);
            });
        }
    }

    return (<div>
        <Row>
            <Col xs={12} md={6}>
                <h2>Redeem Ticket</h2>
                <Button className="btn will-call-btn" onClick={() => history.push("/admin/list-tickets")}>
                    Will Call List
                </Button>
                { qrReaderVisible && <QrReaderWrapper
                    delay={1000}
                    onError={handleError}
                    onScan={(result) => handleScan(result)}
                    id="qrReader"
                    style={{ width: '100%' }}
                /> }
            </Col>
        </Row>
        <Modal show={modalOpen}>
            <ModalHeader className="modal-header">
                Ticket Scanned
            </ModalHeader>
            <ModalBody className="modal-body">
                { fetchingTicket && 
                    <div className="info-text"><h2>Loading...</h2></div>
                }
                { scannedTicket && scannedTicket.map((ticket, index) => {
                    let event = ticketConstants.skus[ticket.sku];
                    return <Row key={`ticket_${index}`} className="redeem-ticket-row">
                        <Col xs={12}>
                            <p className="info-text"><b>Event Name:</b> { event.name }</p>
                            <p className="info-text"><b>Name on Ticket:</b> { ticket.name }</p>
                            <p className="info-text"><b>Quantity:</b> { ticket.quantity }</p>
                            { ticket && parseInt(ticket.redeemed) !== 1 && 
                                <Button 
                                    className="modal-button" 
                                    onClick={ () => {
                                        redeemTicket(index);
                                    } }>
                                    Redeem Ticket
                                </Button> 
                            }
                            { ticket && parseInt(ticket.redeemed) === 1 && 
                                <p className="info-text"><b>This ticket was already redeemed.</b></p>
                            }                     
                        </Col>
                        <hr />
                    </Row> 
                } ) }
                { scannedTicket && scannedTicket.length === 0 && 
                    <div className="info-text">There are no tickets with this ID. Ticket is invalid.</div>
                }
                
                <Row>
                    <Col xs={12}>
                        <Button 
                            className="modal-button" 
                            onClick={ () => {
                                hideModal();
                                showQrReader();
                                setScannedTicket(null);
                            } }>
                                Cancel
                            </Button>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    </div>);
});

const mapStateToProps = (state /*, ownProps*/) => {
    return {
        fetchingTicket: state.fetchingTicket,
        modalOpen: state.showModal,
        qrReaderVisible: state.showQrReader,
        scannedTicket: state.scannedTicket
    };
};

const mapDispatchToProps = dispatch => ({ 
    hideModal: () => dispatch({type: "HIDE_MODAL"}),
    hideQrReader: () => dispatch({ type: "HIDE_QR_READER" }),
    setScannedTicket: (ticket) => dispatch({type: "SET_SCANNED_TICKET", payload: {ticket}}),
    setFetchingTicket: () => dispatch({ type: "SET_FETCHING_TICKET" }),
    showError: (error) => dispatch({ type: "SHOW_ERROR", payload: { error }}),
    showModal: () => dispatch({type: "SHOW_MODAL"}),
    showQrReader: () => dispatch({ type: "SHOW_QR_READER" }),
    showSuccess: (success) => dispatch({ type: "SHOW_SUCCESS", payload: { success }})
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RedeemTicket);

